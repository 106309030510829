<template>
	<b-modal id="print-billing-report" ref="print-billing-report" :title="title" size="xl" ok-title="Download"
		@ok="printReport" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container fluid>
			<b-row no-gutters>
				<b-col md="8" sm="6">
					<b>PRIMARY INFORMATION</b>
				</b-col>
			</b-row>
			<b-row class="mb-4">
				<!-- Account Details -->
				<b-col lg="4" md="12" sm="12" class="mt-4">
					<AccountDetails :billingReport="billingReport" :clientAccount="clientAccount" />
				</b-col>

				<!-- Charge Type Details -->
				<b-col lg="4" md="12" sm="12" class="mt-4">
					<ChargeTypeDetails :billingReport="billingReport" />
				</b-col>

				<!-- Billing Details -->
				<b-col lg="4" md="12" sm="12" class="mt-4">
					<BillingDetails :billingReport="billingReport" :hasEdit="false" :hasPreviousBillings="false" />
				</b-col>
			</b-row>

			<!-- Billing Summary -->
			<hr />
			<b-row class="mt-4">
				<b-col lg="6" md="12" sm="12">
					<b>BILLING SUMMARY</b>
					<BillingComputationSummary :billingReport="billingReport" />
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// Components
import AccountDetails from './primaryDetails/AccountDetails.vue';
import ChargeTypeDetails from './primaryDetails/ChargeTypeDetails.vue';
import BillingDetails from './primaryDetails/BillingDetails.vue';
import BillingComputationSummary from './BillingComputationSummary.vue';

// Util
import ExcelUtil from '@/utils/excelUtil';
import { ExportBillingUtil } from './download/exportBillingUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

// Text and Border Styling
const txtStyleBold = ExportBillingUtil.getTxtStyleBold();
const txtStyleItalic = ExportBillingUtil.getTxtStyleItalic();
const txtStyleRegular = ExportBillingUtil.getTxtStyleRegular();
const txtAlignRight = ExportBillingUtil.getTxtAlignRight();
const txtAlignLeft = ExportBillingUtil.getTxtAlignLeft();
const txtAlignCenter = ExportBillingUtil.getTxtAlignCenter();
const borderStyleThin = ExportBillingUtil.getBorderStyleThin();
const allBordersStyleThin = ExportBillingUtil.getAllBordersStyleThin();
const topBordersStyleThin = ExportBillingUtil.getTopBordersStyleThin();
const bottomBordersStyleThin = ExportBillingUtil.getBottomBordersStyleThin();
const fillStyleBlue = ExportBillingUtil.getFillStyleBlue();
const fillStyleGreen = ExportBillingUtil.getFillStyleGreen();


export default {
	name: 'print-billing-report',
	components: {
		AccountDetails,
		ChargeTypeDetails,
		BillingDetails,
		BillingComputationSummary,
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true
		},
		allUsersObj: {
			type: Object,
			required: true
		},
		allClientAccountsObj: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			billingReport: {},
			clientAccount: {},
			copies: ['Original'],

			currRowIndex: 1,
			isLoading: false,
			isRendering: false,
		};
	},
	computed: {
		title() {
			return 'Print Billing Report ' + this.billingReportNo;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		billingReportNo() {
			let report = this.billingReport ? this.billingReport : {};
			return report.billingReportNo ? report.billingReportNo : "-";
		},
		accountNo() {
			let report = this.billingReport ? this.billingReport : {};
			return report.clientAccountNo ? report.clientAccountNo : "-";
		},
		chargeType() {
			let report = this.billingReport ? this.billingReport : {};
			return report.chargeType ? report.chargeType : "-";
		},
		fileName() {
			return this.billingReportNo + '_' + this.accountNo + '_' + this.chargeType + '.xls';
		}
	},
	mounted() {
		this.billingReport = { ...this.$store.getters.currBillingReport };
		this.onReset(this.billingReport);

		EventBus.$on('onPrintBillingReport', (billingReport) => {
			this.$bvModal.show('print-billing-report');
			this.onReset(billingReport);
		});
	},
	methods: {
		onReset(billingReport) {
			if (billingReport && !_.isEmpty(billingReport)) {
				this.billingReport = { ...billingReport };
				this.clientAccount = this.allClientAccountsObj[billingReport.clientAccountId];
			}

			// Reset
			this.currRowIndex = 1;
			this.copies = ['Original'];

			// Check for loader
			this.isLoading = false;
			this.isRendering = false;
		},
		async printReport(event) {
			event.preventDefault();

			try {
				// show loading indicator
				this.isLoading = true;

				await this.exportToExcel(this.billingReport, this.clientAccount);

			} catch (error) {
				this.$toaster.error('Error loading data for printing. Please try again later.');
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async exportToExcel(billingReport, clientAccount) {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet(billingReport.chargeType);

			await this.renderHeader(workbook, worksheet, billingReport);
			this.renderBillingReportNo(workbook, worksheet, billingReport, clientAccount);

			// Daily Billing
			if (billingReport.chargeType === 'Rental') {
				this.renderDailyBilling(workbook, worksheet, billingReport);
			}

			this.renderAdjustmentsFromAssetMovement(workbook, worksheet, billingReport);

			this.renderOtherAdjustments(workbook, worksheet, billingReport,);
			this.renderSummary(workbook, worksheet, billingReport);
			this.renderSignatories(workbook, worksheet, billingReport);

			// Generate Excel file
			workbook.xlsx.writeBuffer().then(buffer => {
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				FileSaver.saveAs(blob, this.fileName);
			});
		},

		async renderHeader(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getHeaderDetails(billingReport, this.allCompaniesObj);

			// Starting Index
			this.currRowIndex = 1;

			// Set the width of the column in Excel's "Best-Fit" units
			worksheet.getColumn('A').width = 16;
			worksheet.getColumn('B').width = 16;

			// Set Asset Owner's Title, Address and Contact No
			if (details.logoUrl.length > 0) {
				// Add Company Logo
				let extension = details.logoFileName.split('.').pop();
				const imageBase64 = await this.loadImageAsBase64(details.logoUrl);
				const imageId = workbook.addImage({
					base64: imageBase64,
					extension: extension,
				});
				worksheet.addImage(imageId, {
					tl: { col: 0.4, row: 0.1 }, // Top-left cell (A1)
					ext: { width: 60, height: 60 }, // Width and height of the image
				});

				// Add the Asset Owner Details
				ExcelUtil.setCell(worksheet.getCell('B' + this.currRowIndex), details.assetOwner, { size: 12, bold: true }, txtAlignLeft);
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('B' + this.currRowIndex), details.address, txtStyleRegular, txtAlignLeft);
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('B' + this.currRowIndex), details.contactNo, txtStyleRegular, txtAlignLeft);
			} else {
				// Add the Asset Owner Details
				ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), details.assetOwner, { size: 12, bold: true }, txtAlignLeft);
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), details.address, txtStyleRegular, txtAlignLeft);
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), details.contactNo, txtStyleRegular, txtAlignLeft);
			}
		},
		renderBillingReportNo(workbook, worksheet, billingReport, clientAccount) {
			// Details
			let details = ExportBillingUtil.getBillingReportDetails(billingReport, this.allCompaniesObj, clientAccount);

			// Section Header
			this.currRowIndex += 2;
			this.renderTableHeader(worksheet.getCell('A' + this.currRowIndex), details.sectionHeader, txtAlignLeft, fillStyleBlue, true, topBordersStyleThin);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			// Section Subheader
			this.currRowIndex++;
			this.renderTableHeader(worksheet.getCell('A' + this.currRowIndex), details.sectionSubHeader, txtAlignLeft, fillStyleBlue, false, bottomBordersStyleThin);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			this.currRowIndex++;
			const tableColumns = ['ACCOUNT', '', '', 'CHARGE TYPE', '', '', 'BILLING DETAILS', ''];
			for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1)) {
				const cell = worksheet.getCell(column + this.currRowIndex);
				const content = tableColumns[index++];
				this.renderTableData(cell, content, txtAlignCenter, txtStyleBold);
			}
			worksheet.mergeCells('A' + this.currRowIndex + ':C' + this.currRowIndex);
			worksheet.mergeCells('D' + this.currRowIndex + ':F' + this.currRowIndex);
			worksheet.mergeCells('G' + this.currRowIndex + ':I' + this.currRowIndex);

			// Next Row
			this.currRowIndex++

			// Define data in arrays for labels, values, and formatting
			const accountLabels = ['Account No', 'Client', 'Principal', 'Address'];
			const accountValues = details.accountDetails;

			// Set labels and values in cells using loops
			for (let i = 0; i < accountLabels.length; i++) {
				ExcelUtil.setCell(worksheet.getCell('A' + (this.currRowIndex + i)), accountLabels[i], txtStyleBold);
				ExcelUtil.setCell(worksheet.getCell('B' + (this.currRowIndex + i)), accountValues[i], txtStyleRegular);
			}

			// Define data for the second set of labels, values, and formatting
			const chargeTypeLabels = details.chargeTypeLabels;
			const chargeTypeValues = details.chargeTypeValues;

			// Set billing labels and values in cells using loops
			for (let i = 0; i < chargeTypeLabels.length; i++) {
				ExcelUtil.setCell(worksheet.getCell('D' + (this.currRowIndex + i)), chargeTypeLabels[i], txtStyleBold);
				ExcelUtil.setCell(worksheet.getCell('E' + (this.currRowIndex + i)), chargeTypeValues[i], txtStyleRegular);
			}

			// Define data for the third set of labels, values, and formatting
			const billingLabels = ['Billing No', 'Start Date', 'End Date', 'Due Date'];
			const billingValues = details.billingDetails;

			// Set billing labels and values in cells using loops
			for (let i = 0; i < billingLabels.length; i++) {
				ExcelUtil.setCell(worksheet.getCell('G' + (this.currRowIndex + i)), billingLabels[i], txtStyleBold);
				ExcelUtil.setCell(worksheet.getCell('H' + (this.currRowIndex + i)), billingValues[i], txtStyleRegular, txtAlignRight);
			}

			// Border Styling
			for (let column = 'B'; column <= 'F'; column = ExcelUtil.increment(column, 1)) {
				worksheet.getColumn(column).width = 16;
			}
			for (let i = 0; i < 9; i++) {
				let column = ExcelUtil.increment('A', i);
				ExcelUtil.setBorder(worksheet.getCell(column + '7'), 'top', borderStyleThin);
				ExcelUtil.setBorder(worksheet.getCell(column + '11'), 'bottom', borderStyleThin);
			}
			for (let i = 0; i < 6; i++) {
				ExcelUtil.setBorder(worksheet.getCell('A' + (6 + i)), 'left', borderStyleThin);
				ExcelUtil.setBorder(worksheet.getCell('D' + (6 + i)), 'left', borderStyleThin);
				ExcelUtil.setBorder(worksheet.getCell('G' + (6 + i)), 'left', borderStyleThin);
				ExcelUtil.setBorder(worksheet.getCell('I' + (6 + i)), 'right', borderStyleThin);
			}

			// Next Row
			this.currRowIndex += 4;
		},
		renderDailyBilling(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getDailyBilling(billingReport);

			// Daily Billing
			this.currRowIndex++;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), 'Daily Billing', txtStyleBold);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			// Daily Billing Table Columns
			this.currRowIndex++;
			const tableColumns = details.tableColumns;
			for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1)) {
				this.renderTableHeader(worksheet.getCell(column + this.currRowIndex), tableColumns[index++], txtAlignCenter, fillStyleGreen);
			}
			worksheet.mergeCells('A' + this.currRowIndex + ':C' + this.currRowIndex);
			worksheet.mergeCells('D' + this.currRowIndex + ':F' + this.currRowIndex);
			worksheet.mergeCells('G' + this.currRowIndex + ':I' + this.currRowIndex);

			// Daily Billing Table Values
			this.currRowIndex++;
			const tableValues = details.tableValues;
			if (!_.isEmpty(tableValues)) {
				for (let i = 0; i < tableValues.length; i++) {
					for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1), ++index) {

						let txtAlign = txtAlignLeft;
						if (index === 3 || index === 6) {
							txtAlign = txtAlignRight;
						} else if (index === 0) {
							txtAlign = txtAlignCenter;
						}

						this.renderTableData(worksheet.getCell(column + this.currRowIndex), tableValues[i][index], txtAlign, txtStyleRegular);
					}

					worksheet.mergeCells('A' + this.currRowIndex + ':C' + this.currRowIndex);
					worksheet.mergeCells('D' + this.currRowIndex + ':F' + this.currRowIndex);
					worksheet.mergeCells('G' + this.currRowIndex + ':I' + this.currRowIndex);

					this.currRowIndex++;
				}

				// Nothing Follows
				this.renderNothingFollows(workbook, worksheet);

				// Total Amount
				ExcelUtil.setCell(worksheet.getCell('D' + this.currRowIndex), 'TOTAL AMOUNT', txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('G' + this.currRowIndex), details.totalAmount, txtStyleBold, txtAlignRight);
				worksheet.mergeCells('D' + this.currRowIndex + ':F' + this.currRowIndex);
				worksheet.mergeCells('G' + this.currRowIndex + ':I' + this.currRowIndex);
				this.currRowIndex++;
			}
		},
		renderAdjustmentsFromAssetMovement(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getAdjustmentsFromAssetMovement(billingReport);

			// Beginning Balance
			if (billingReport.chargeType === 'Rental') {
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('H' + this.currRowIndex), 'Total Assets-on-Hire', txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('I' + this.currRowIndex), details.labelForThePeriod, txtStyleBold, txtAlignRight);
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('F' + this.currRowIndex), 'Beginning Balance as of', txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('G' + this.currRowIndex), details.startDate, txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('H' + this.currRowIndex), details.startingQuantity, txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('I' + this.currRowIndex), details.startingDays, txtStyleBold, txtAlignRight);
			}

			// Transactions 
			this.currRowIndex++;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), 'Adjustments from Asset Movement', txtStyleBold);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			// Transactions Table Columns
			this.currRowIndex++;
			const tableColumns = details.tableColumns;
			for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1)) {
				this.renderTableHeader(worksheet.getCell(column + this.currRowIndex), tableColumns[index++], txtAlignCenter, fillStyleGreen);
			}
			if (billingReport.chargeType === 'Trucking') {
				worksheet.mergeCells('E' + this.currRowIndex + ':F' + this.currRowIndex);
				worksheet.mergeCells('H' + this.currRowIndex + ':I' + this.currRowIndex);
			} else {
				worksheet.mergeCells('C' + this.currRowIndex + ':E' + this.currRowIndex);
			}

			// Transactions Table Values
			this.currRowIndex++;
			const tableValues = details.transactions;
			if (!_.isEmpty(tableValues)) {
				for (let i = 0; i < tableValues.length; i++) {
					for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1), ++index) {
						let txtStyle = billingReport.chargeType !== 'Trucking' && index === 2 ? txtStyleItalic : txtStyleRegular;

						let txtAlign = txtAlignLeft;
						if (billingReport.chargeType === 'Trucking') {
							if (index === 7 || index === 8) {
								txtAlign = txtAlignRight;
							} else if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 6) {
								txtAlign = txtAlignCenter;
							}
						} else {
							if (index === 6 || index === 7 || index === 8) {
								txtAlign = txtAlignRight;
							} else if (index === 0 || index === 1 || index === 4) {
								txtAlign = txtAlignCenter;
							}
						}

						this.renderTableData(worksheet.getCell(column + this.currRowIndex), tableValues[i][index], txtAlign, txtStyle);
					}

					if (billingReport.chargeType === 'Trucking') {
						worksheet.mergeCells('E' + this.currRowIndex + ':F' + this.currRowIndex);
						worksheet.mergeCells('H' + this.currRowIndex + ':I' + this.currRowIndex);
					} else {
						worksheet.mergeCells('C' + this.currRowIndex + ':E' + this.currRowIndex);
					}

					this.currRowIndex++;
				}

				// Nothing Follows
				this.renderNothingFollows(workbook, worksheet);

			} else {
				this.renderTableData(worksheet.getCell('A' + this.currRowIndex), 'No asset movement for this billing period**', txtAlignCenter, txtStyleItalic);
				worksheet.mergeCells('A' + this.currRowIndex + ':H' + this.currRowIndex);
				this.currRowIndex++;
			}

			// Delayed Transfer
			if (billingReport.chargeType === 'Rental') {
				this.renderAdjustmentsFromDelayedTransfers(workbook, worksheet, billingReport);
			}

			// Ending Balance
			if (billingReport.chargeType === 'Rental') {
				this.currRowIndex++;
				ExcelUtil.setCell(worksheet.getCell('F' + this.currRowIndex), 'Ending Balance as of ', txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('G' + this.currRowIndex), details.endDate, txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('H' + this.currRowIndex), details.endingQuantity, txtStyleBold, txtAlignRight);
				ExcelUtil.setCell(worksheet.getCell('I' + this.currRowIndex), details.endingDays, txtStyleBold, txtAlignRight);
			}

			worksheet.getColumn('E').width = 20;
			worksheet.getColumn('F').width = 15;
			worksheet.getColumn('G').width = 15;
			worksheet.getColumn('H').width = 20;
			worksheet.getColumn('I').width = 25;
		},
		renderAdjustmentsFromDelayedTransfers(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getAdjustmentsFromDelayedTransfers(billingReport);

			// Delayed Transfer
			this.currRowIndex++;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), 'Adjustments from Delayed Transfers', txtStyleBold);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			// Delayed Transfer Table Columns
			this.currRowIndex++;
			const tableColumns = details.tableColumns;
			for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1)) {
				this.renderTableHeader(worksheet.getCell(column + this.currRowIndex), tableColumns[index++], txtAlignCenter, fillStyleGreen);
			}

			// Delayed Transfer Table Values
			this.currRowIndex++;
			const tableValues = details.delayedTransfers;
			if (!_.isEmpty(tableValues)) {
				for (let i = 0; i < tableValues.length; i++) {
					for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1), ++index) {

						let txtAlign = txtAlignLeft;
						if (index === 4 || index === 6 || index === 7 || index === 8) {
							txtAlign = txtAlignRight;
						} else if (index === 0 || index === 1 || index === 5) {
							txtAlign = txtAlignCenter;
						}

						this.renderTableData(worksheet.getCell(column + this.currRowIndex), tableValues[i][index], txtAlign, txtStyleRegular);
					}

					this.currRowIndex++;
				}

				// Nothing Follows
				this.renderNothingFollows(workbook, worksheet);

			} else {
				this.renderTableData(worksheet.getCell('A' + this.currRowIndex), 'No delayed transfer for this billing period**', txtAlignCenter, txtStyleItalic);
				worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);
				this.currRowIndex++;
			}

		},
		renderOtherAdjustments(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getOtherAdjustments(billingReport);

			// Other Adjustments
			this.currRowIndex++;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), 'Other Adjustments', txtStyleBold);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);

			this.currRowIndex++;
			const tableColumns = ['Adjustment Type', 'Remarks', '', '', '', '', '', 'Amount', ''];
			for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1)) {
				this.renderTableHeader(worksheet.getCell(column + this.currRowIndex), tableColumns[index++], txtAlignCenter, fillStyleGreen);
			}
			worksheet.mergeCells('B' + this.currRowIndex + ':G' + this.currRowIndex);
			worksheet.mergeCells('H' + this.currRowIndex + ':I' + this.currRowIndex);

			this.currRowIndex++;
			const tableValues = details.adjustments;
			if (!_.isEmpty(tableValues)) {
				for (let i = 0; i < tableValues.length; i++) {
					for (let column = 'A', index = 0; column <= 'I'; column = ExcelUtil.increment(column, 1), ++index) {
						let txtStyle = index === 1 ? txtStyleItalic : txtStyleRegular;

						let txtAlign = txtAlignRight;
						if (index === 0) {
							txtAlign = txtAlignCenter;
						} else if (index === 1) {
							txtAlign = txtAlignLeft;
						}

						this.renderTableData(worksheet.getCell(column + '' + this.currRowIndex), tableValues[i][index], txtAlign, txtStyle);
					}
					worksheet.mergeCells('B' + this.currRowIndex + ':G' + this.currRowIndex);
					worksheet.mergeCells('H' + this.currRowIndex + ':I' + this.currRowIndex);
					this.currRowIndex++;
				}

				// Nothing Follows
				this.renderNothingFollows(workbook, worksheet);

			} else {
				this.renderTableData(worksheet.getCell('A' + this.currRowIndex), 'No other adjustments for this billing period**', txtAlignCenter, txtStyleItalic);
				worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);
				this.currRowIndex++;
			}
		},
		renderSummary(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getBillingSummary(billingReport);

			// Summary
			this.currRowIndex++;
			ExcelUtil.setCell(worksheet.getCell('H' + this.currRowIndex), 'Summary:', txtStyleBold);

			// Define data in arrays for labels, values, and formatting
			const summaryLabels = ['Total ' + this.chargeType + ' Amount', 'Total Adjustments **', 'Total VAT EX Amount', '12% VAT Amount'];
			const summaryValues = [details.totalChargeTypeAmount, details.totalAdjustmentAmount, details.totalVATExclusiveAmount, details.total12PercentVATAmount];

			// Set labels and values in cells using loops
			this.currRowIndex++;
			for (let i = 0; i < summaryLabels.length; i++) {
				ExcelUtil.setCell(worksheet.getCell('H' + (this.currRowIndex + i)), summaryLabels[i], txtStyleRegular);
				ExcelUtil.setCell(worksheet.getCell('I' + (this.currRowIndex + i)), summaryValues[i], txtStyleRegular, txtAlignRight);
			}

			let totalAmountLabelCell = worksheet.getCell('H' + (this.currRowIndex + 4));
			ExcelUtil.setCell(totalAmountLabelCell, 'Total VAT IN Amount', { bold: true, color: { argb: 'ffffff' } }, txtAlignLeft);
			totalAmountLabelCell.fill = fillStyleBlue;

			let totalAmountValueCell = worksheet.getCell('I' + (this.currRowIndex + 4));
			ExcelUtil.setCell(totalAmountValueCell, details.totalVATInclusiveAmount, { bold: true, color: { argb: 'ffffff' } }, txtAlignRight);
			totalAmountValueCell.fill = fillStyleBlue;
		},
		renderSignatories(workbook, worksheet, billingReport) {
			// Details
			let details = ExportBillingUtil.getSignatories(billingReport, this.allUsersObj);

			// Prepared By and Checked By
			this.currRowIndex += 2;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), 'Prepared By:', txtStyleRegular);
			ExcelUtil.setCell(worksheet.getCell('C' + this.currRowIndex), 'Checked and Approved By:', txtStyleRegular);

			// Signatories
			this.currRowIndex += 2;
			ExcelUtil.setCell(worksheet.getCell('A' + this.currRowIndex), details.preparedBy, txtStyleBold);
			ExcelUtil.setCell(worksheet.getCell('C' + this.currRowIndex), details.approvedBy, txtStyleBold);
		},
		renderNothingFollows(workbook, worksheet) {
			this.renderTableData(worksheet.getCell('A' + this.currRowIndex), '- Nothing Follows -', txtAlignCenter, txtStyleItalic);
			worksheet.mergeCells('A' + this.currRowIndex + ':I' + this.currRowIndex);
			this.currRowIndex++;
		},

		// UTILS
		renderTableHeader(cell, value, alignment = null, fill = null, isBold = true, borderStyle) {
			ExcelUtil.setCell(cell, value, { size: 11, bold: isBold, color: { argb: 'ffffff' } });
			if (borderStyle) {
				cell.border = borderStyle;
			}
			if (alignment) {
				cell.alignment = alignment;
			}
			if (fill) {
				cell.fill = fill;
			}
		},
		renderTableData(cell, value, alignment = null, font = null) {
			ExcelUtil.setCell(cell, value, { size: 10 });
			cell.border = allBordersStyleThin;
			if (alignment) {
				cell.alignment = alignment;
			}
			if (font) {
				cell.font = font;
			}
		},
		loadImageAsBase64(url) {
			return new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.open('GET', url, true);
				xhr.responseType = 'blob';
				xhr.onload = function () {
					const reader = new FileReader();
					reader.readAsDataURL(xhr.response);
					reader.onload = function () {
						resolve(reader.result.split(',')[1]);
					};
				};
				xhr.onerror = function (error) {
					reject(error);
				};
				xhr.send();
			});
		},
	},
	beforeDestroy() {
		EventBus.$off('onPrintBillingReport');
	},
}
</script>