
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from "@/utils/dateutil";
import { DispatchUtil } from "@/utils/dispatchUtil";
import { UserUtil } from "@/utils/userutil";

// Others
import _ from 'lodash';


function getHeaderDetails(billingReport, allCompaniesObj) {
  let assetOwnerId = billingReport.assetOwnerId;
  let assetOwnerObj = allCompaniesObj[assetOwnerId] ? allCompaniesObj[assetOwnerId] : {};
  let assetOwner = assetOwnerObj.name ? assetOwnerObj.name : '';
  let address = assetOwnerObj.address ? assetOwnerObj.address : '';
  let contactNo = assetOwnerObj.contactNo ? assetOwnerObj.contactNo : '';
  let logoFileName = assetOwnerObj.companyLogo && assetOwnerObj.companyLogo.name ? assetOwnerObj.companyLogo.name : '';
  let logoUrl = assetOwnerObj.companyLogo && assetOwnerObj.companyLogo.url ? assetOwnerObj.companyLogo.url : '';

  return {
    assetOwner: assetOwner,
    address: address,
    contactNo: contactNo,
    logoFileName: logoFileName,
    logoUrl: logoUrl
  }
}

function getContractPeriodDisplay(clientAccount) {
  return 'Contract Period: ' + DateUtil.getFormattedDateShorten(clientAccount.startDate)
    + ' - ' + DateUtil.getFormattedDateShorten(clientAccount.endingDate)
    + ' (' + clientAccount.contractPeriod + ')';
}

function getBillingReportDetails(billingReport, allCompaniesObj, clientAccount) {
  let sectionHeader = 'BILLING REPORT ' + billingReport.billingReportNo;
  let sectionSubHeader = getContractPeriodDisplay(clientAccount);

  // Account Details
  let accountNo = billingReport.clientAccountNo ? billingReport.clientAccountNo : '';
  let principal = billingReport.principal ? billingReport.principal : '';
  let clientId = billingReport.clientId ? billingReport.clientId : '';
  let clientObj = allCompaniesObj[clientId] ? allCompaniesObj[clientId] : {};
  let client = clientObj.name ? clientObj.name : '';
  let address = clientObj.address ? clientObj.address : '';

  // Charge Type Details
  let chargeType = billingReport.chargeType;
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  let chargeTypeLabels = [];
  let chargeTypeValues = [];
  if (chargeType === 'Rental') {
    let assetType = chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
    let rentRate = chargeTypeDetails.rentRate ? formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.rentRate) : '';
    let rentFrequency = chargeTypeDetails.rentFrequency ? chargeTypeDetails.rentFrequency : '';

    chargeTypeLabels = ['Charge Type', 'Asset Type', 'Rent Rate', 'Rent Frequency'];
    chargeTypeValues = [chargeType, assetType, rentRate, rentFrequency];

  } else if (chargeType === 'Trucking') {
    chargeTypeLabels = ['Charge Type'];
    chargeTypeValues = [chargeType];

  } else {
    let assetType = chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
    let vatExclusiveRate = chargeTypeDetails.vatExclusiveRate ? formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.vatExclusiveRate) : '';

    chargeTypeLabels = ['Charge Type', 'Asset Type', 'VAT EX Rate'];
    chargeTypeValues = [chargeType, assetType, vatExclusiveRate];
  }

  // Billing Details
  let billingNumber = billingReport.billingNumber ? billingReport.billingNumber : '';
  let startDate = billingReport.startDate ? DateUtil.getFormattedDateShorten(billingReport.startDate) : '';
  let endDate = billingReport.endDate ? DateUtil.getFormattedDateShorten(billingReport.endDate) : '';
  let dueDate = billingReport.dueDate ? DateUtil.getFormattedDateShorten(billingReport.dueDate) : '';

  return {
    sectionHeader: sectionHeader,
    sectionSubHeader: sectionSubHeader,
    accountDetails: [accountNo, client, principal, address],
    chargeTypeLabels: chargeTypeLabels,
    chargeTypeValues: chargeTypeValues,
    billingDetails: [billingNumber, startDate, endDate, dueDate]
  }
}

function getQuantityPerDay(currDate, quantity, billingReport) {
  let startDateTimeStamp = DateUtil.startDateTimeStamp(currDate);
  let endDateTimeStamp = DateUtil.endDateTimeStamp(currDate);
  let assetTypeId = billingReport.chargeTypeDetails.assetTypeId;

  // Add From Hires
  let hires = _.filter(billingReport.transactions, o => {
    return o.dateReceived >= startDateTimeStamp && o.dateReceived <= endDateTimeStamp
      && billingReport.clientId === o.destination.companyId;
  });

  if (!_.isEmpty(hires)) {
    _.forEach(hires, dispatch => {
      quantity += getDispatchAssetQuantity(dispatch, assetTypeId);
    });
  }

  // Deduct From Dehires
  let dehires = _.filter(billingReport.transactions, o => {
    return o.dateReceived >= startDateTimeStamp && o.dateReceived <= endDateTimeStamp
      && billingReport.clientId === o.source.companyId;
  });

  if (!_.isEmpty(dehires)) {
    _.forEach(dehires, dispatch => {
      quantity -= getDispatchAssetQuantity(dispatch, assetTypeId);
    });
  }

  // Deduct from Delayed Transfers
  let delayedTransfers = _.filter(billingReport.delayedTransfers, o => {
    return o.delayedTransferMaturityDate >= startDateTimeStamp && o.delayedTransferMaturityDate <= endDateTimeStamp
      && billingReport.clientId === o.source.companyId;
  });

  if (!_.isEmpty(delayedTransfers)) {
    _.forEach(delayedTransfers, dispatch => {
      quantity -= getDispatchAssetQuantity(dispatch, assetTypeId);
    });
  }

  return quantity;
}

function getDispatchAssetQuantity(dispatch, assetTypeId) {
  let dispatchAsset = _.find(dispatch.assets, o => {
    return o.assetTypeId === assetTypeId;
  });

  return dispatchAsset.actualQuantity;
}

function getDailyBilling(billingReport) {
  // Compute Daily Billing
  let dateArray = DateUtil.getDatesBetween(billingReport.startDate, billingReport.endDate);
  let startingQuantity = billingReport.chargeTypeDetails.startingQuantity;
  let rentRate = billingReport.chargeTypeDetails.rentRate;
  let currency = getCurrency(billingReport);
  let quantity = startingQuantity;

  let items = [];
  _.forEach(dateArray, currDate => {
    let item = {};
    item.date = DateUtil.getFormattedDateShorten(currDate);

    quantity = getQuantityPerDay(currDate, quantity, billingReport);
    item.quantity = quantity;

    item.amount = quantity * rentRate;
    item.amountDisplay = formatMoneyValue(currency, item.amount);

    items.push(item);
  });

  // Table Columns
  let tableColumns = ['Date', '', '', 'Total No. of Assets', '', '', 'Amount NET of VAT', '', ''];

  // Table Values
  let tableValues = [];
  _.forEach(items, item => {
    tableValues.push([item.date, '', '', item.quantity, '', '', item.amountDisplay, '', '']);
  });

  // Total Amount
  let totalAmount = _.sumBy(items, 'amount');
  let totalAmountDisplay = formatMoneyValue(currency, totalAmount);

  return {
    tableColumns,
    tableValues,
    totalAmount: totalAmountDisplay
  }
}

function getAdjustmentsFromAssetMovement(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  let startDate = billingReport.startDate ? DateUtil.getFormattedDateShorten(billingReport.startDate) : '';
  let endDate = billingReport.endDate ? DateUtil.getFormattedDateShorten(billingReport.endDate) : '';
  let startingQuantity = chargeTypeDetails.startingQuantity ? formatNumber(chargeTypeDetails.startingQuantity) : 0;
  let endingQuantity = chargeTypeDetails.endingQuantity ? formatNumber(chargeTypeDetails.endingQuantity) : 0;
  let startingDays = chargeTypeDetails.startingDays ? formatNumber(chargeTypeDetails.startingDays) : 0;
  let endingDays = chargeTypeDetails.endingDays ? formatNumber(chargeTypeDetails.endingDays) : 0;

  let tableColumns = getAssetMovementTableColumns(billingReport);
  let labelForThePeriod = getLabelForThePeriod(chargeTypeDetails.rentFrequency);
  let transactions = processTransactionRow(billingReport);

  return {
    startDate: startDate,
    endDate: endDate,
    startingQuantity,
    endingQuantity,
    startingDays,
    endingDays,
    transactions,
    tableColumns,
    labelForThePeriod
  }
}

function getAssetMovementTableColumns(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  if (billingReport.chargeType === 'Rental') {
    if (chargeTypeDetails.rentFrequency === 'Per Day') {
      return ['Date', 'Dispatch No.', 'Notes', '', '', 'Type', 'Quantity', 'Calendar Days', 'Total Asset Days'];
    } else {
      return ['Date', 'Dispatch No.', 'Notes', '', '', 'Type', 'Quantity', 'Hours', 'Total Asset Hours'];
    }

  } else if (billingReport.chargeType === 'Trucking') {
    return ['Date', 'Dispatch No.', 'Type', 'Truck Type', 'Plate No./Conduction No.', '', 'Billable', 'VAT EX Rate'];
  } else {
    return ['Date', 'Dispatch No.', 'Notes', '', '', 'Type', 'Quantity', 'Total ' + billingReport.chargeType, 'VAT EX Amount'];
  }
}

function getLabelForThePeriod(rentFrequency) {
  if (rentFrequency === 'Per Day') {
    return 'Total Asset Days for the Period';
  } else if (rentFrequency === 'Per Hour') {
    return 'Total Asset Hours for the Period';
  }
  return '';
}

function processTransactionRow(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  let transactions = billingReport.transactions ? billingReport.transactions : [];

  let items = [];
  _.forEach(transactions, transaction => {
    let dateReceived = DateUtil.getFormattedDateShorten(transaction.dateReceived);
    let dispatchNo = transaction.dispatchNo;
    let notes = transaction.notes;
    let type = transaction.type;

    if (billingReport.chargeType === 'Rental') {
      let transactionQty = type === 'Hire' ? transaction.quantity : -1 * transaction.quantity;
      let quantity = formatNumber(transactionQty);

      if (chargeTypeDetails.rentFrequency === 'Per Day') {
        let calendarDays = formatNumber(transaction.calendarDays);
        let totalDays = formatNumber(transaction.totalDays);

        items.push([dateReceived, dispatchNo, notes, "", "", type, quantity, calendarDays, totalDays]);
      } else {
        let calendarHours = formatNumber(transaction.calendarHours);
        let totalHours = formatNumber(transaction.totalHours);

        items.push([dateReceived, dispatchNo, notes, "", "", type, quantity, calendarHours, totalHours]);
      }

    } else if (billingReport.chargeType === 'Trucking') {
      let truckType = transaction.truckType;
      let plateNo = transaction.transportation.plateNo;
      let billable = transaction.billable ? 'YES' : 'NO';
      let vatExclusiveRate = transaction.vatExclusiveRate ? formatMoneyValue(chargeTypeDetails.currency, transaction.vatExclusiveRate) : formatMoneyValue(chargeTypeDetails.currency, 0);

      items.push([dateReceived, dispatchNo, type, truckType, plateNo, "", billable, vatExclusiveRate]);

    } else {
      let quantity = transaction.quantity;
      let classifiedColumn = 'total' + billingReport.chargeType;
      let classifiedValue = transaction[classifiedColumn] ? formatNumber(transaction[classifiedColumn]) : '';
      let vatExclusiveAmount = transaction.vatExclusiveAmount ? formatMoneyValue(chargeTypeDetails.currency, transaction.vatExclusiveAmount) : formatMoneyValue(chargeTypeDetails.currency, 0);

      items.push([dateReceived, dispatchNo, notes, "", "", type, quantity, classifiedValue, vatExclusiveAmount]);
    }
  });

  return items;
}

function getAdjustmentsFromDelayedTransfers(billingReport) {
  let tableColumns = getDelayedTransferTableColumns(billingReport);
  let delayedTransfers = processDelayedTransferRow(billingReport);

  return {
    tableColumns,
    delayedTransfers
  }
}

function getDelayedTransferTableColumns(billingReport) {
  let tableColumns = [];
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  if (chargeTypeDetails.rentFrequency === 'Per Day') {
    tableColumns = ['Maturity Date', 'Dispatch No.', 'Source', 'Destination', 'Delayed Transfer Days', 'Date Received', 'Quantity', 'Calendar Days', 'Total Asset Days'];
  } else {
    tableColumns = ['Maturity Date', 'Dispatch No.', 'Source', 'Destination', 'Delayed Transfer Days', 'Date Received', 'Quantity', 'Hours', 'Total Asset Hours'];
  }
  return tableColumns;
}

function processDelayedTransferRow(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  let delayedTransfers = billingReport.delayedTransfers ? billingReport.delayedTransfers : [];

  let items = [];

  _.forEach(delayedTransfers, transfer => {
    let maturityDate = DateUtil.getFormattedDateShorten(transfer.delayedTransferMaturityDate);
    let dispatchNo = transfer.dispatchNo;
    let source = DispatchUtil.getCompanyLocationDisplay(transfer.source);
    let destination = DispatchUtil.getCompanyLocationDisplay(transfer.destination);
    let delayedTransferDays = transfer.delayedTransferDays;
    let dateReceived = DateUtil.getFormattedDateShorten(transfer.dateReceived);
    let quantity = formatNumber(-1 * transfer.quantity);

    if (chargeTypeDetails.rentFrequency === 'Per Day') {
      let calendarDays = formatNumber(transfer.calendarDays);
      let totalDays = formatNumber(transfer.totalDays);
      items.push([maturityDate, dispatchNo, source, destination, delayedTransferDays, dateReceived, quantity, calendarDays, totalDays]);
    } else {
      let calendarHours = formatNumber(transfer.calendarHours);
      let totalHours = formatNumber(transfer.totalHours);
      items.push([maturityDate, dispatchNo, source, destination, delayedTransferDays, dateReceived, quantity, calendarHours, totalHours]);
    }
  });

  return items;
}

function getOtherAdjustments(billingReport) {
  let adjustments = billingReport.adjustments ? billingReport.adjustments : [];
  let currency = getCurrency(billingReport);

  let items = [];
  _.forEach(adjustments, adjustment => {
    let type = adjustment.type;
    let remarks = adjustment.remarks;
    let adjustmentAmt = type === 'Additional' ? adjustment.amount : -1 * adjustment.amount;
    let amount = formatMoneyValue(currency, adjustmentAmt);

    items.push([type, remarks, '', '', '', '', '', amount]);
  });

  return {
    adjustments: items
  }
}

function getBillingSummary(billingReport) {
  let totalChargeTypeAmount = billingReport.totalChargeTypeAmount ? billingReport.totalChargeTypeAmount : 0;
  let totalAdjustmentAmount = billingReport.totalAdjustmentAmount ? billingReport.totalAdjustmentAmount : 0;
  let totalVATExclusiveAmount = billingReport.totalVATExclusiveAmount ? billingReport.totalVATExclusiveAmount : 0;
  let total12PercentVATAmount = billingReport.total12PercentVATAmount ? billingReport.total12PercentVATAmount : 0;
  let totalVATInclusiveAmount = billingReport.totalVATInclusiveAmount ? billingReport.totalVATInclusiveAmount : 0;

  let currency = getCurrency(billingReport);
  return {
    totalChargeTypeAmount: formatMoneyValue(currency, totalChargeTypeAmount),
    totalAdjustmentAmount: formatMoneyValue(currency, totalAdjustmentAmount),
    totalVATExclusiveAmount: formatMoneyValue(currency, totalVATExclusiveAmount),
    total12PercentVATAmount: formatMoneyValue(currency, total12PercentVATAmount),
    totalVATInclusiveAmount: formatMoneyValue(currency, totalVATInclusiveAmount),
  }
}

function getSignatories(billingReport, allUsersObj) {
  let preparedBy = billingReport.createdBy ? billingReport.createdBy : '';
  let preparedByName = preparedBy.length > 0 ? UserUtil.getUserAbbreviatedName(allUsersObj, preparedBy) : '';

  let approvedBy = billingReport.approvedBy ? billingReport.approvedBy : '';
  let approvedByName = approvedBy.length > 0 ? UserUtil.getUserAbbreviatedName(allUsersObj, approvedBy) : '';

  return {
    preparedBy: preparedByName,
    approvedBy: approvedByName
  }
}

function getCurrency(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  return chargeTypeDetails.currency ? chargeTypeDetails.currency : 'PHP';
}

function formatMoneyValue(currency, number) {
  number = parseFloat(number);
  return BillingReportUtil.formatMoneyValue(currency, number);
}

function formatNumber(number) {
  number = parseInt(number);
  return BillingReportUtil.formatNumber(number);
}

function getTxtStyleRegular() {
  return { size: 10 };
}

function getTxtStyleBold() {
  return { size: 10, bold: true };
}

function getTxtStyleItalic() {
  return { size: 10, italic: true };
}

function getTxtAlignRight() {
  return { horizontal: 'right' }
}

function getTxtAlignLeft() {
  return { horizontal: 'left' }
}

function getTxtAlignCenter() {
  return { horizontal: 'center' }
}

function getBorderStyleThin() {
  return { style: 'thin', color: { argb: '000000' } };
}

function getAllBordersStyleThin() {
  return {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };
}

function getTopBordersStyleThin() {
  return {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };
}

function getBottomBordersStyleThin() {
  return {
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };
}

function getFillStyleBlue() {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '024F98' }
  };
}

function getFillStyleGreen() {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '008036' }
  };
}

export const ExportBillingUtil = {
  getHeaderDetails,
  getBillingReportDetails,
  getDailyBilling,
  getAdjustmentsFromAssetMovement,
  getAdjustmentsFromDelayedTransfers,
  getOtherAdjustments,
  getBillingSummary,
  getSignatories,

  getTxtStyleRegular,
  getTxtStyleBold,
  getTxtStyleItalic,
  getTxtAlignRight,
  getTxtAlignLeft,
  getTxtAlignCenter,
  getBorderStyleThin,
  getAllBordersStyleThin,
  getTopBordersStyleThin,
  getBottomBordersStyleThin,
  getFillStyleBlue,
  getFillStyleGreen,
};